/* eslint-disable @typescript-eslint/no-explicit-any */
import { Methods } from '../../utils/customHooks/apiTypes';
import {
  TASKS_SLICE, FETCH_TASK_INFO,
  TASK_REPORT_PRECOMPUTE_API_PATH,
  TASK_REPORT_API_GET_METHOD, FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION, FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART,
  TASK_REPORT_OPERATORCARD_API_PATH,
  FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY,
  FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART,
  FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN,
  FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL,
  FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH,
  FETCH_PRECOMPUTE_RAW_TASK_REPORTS,
  FETCH_TASK_REPORT_OPERATOR_CARD,
  FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD,
  TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { preparePrecompFilterList, searchColumnString } from './taskReportUtils';

interface APIRequestObj {
  path: string;
  method:Methods;
  body?: {
    columns: string[];
    table: string;
    filters: string[];
  };
  header?: {
  operator: string;
  };
 }

const generateQueryParams = (primaryScope: string, secondaryScope: string, taskNumber: string, type: string, info: string) => {
  return `primary_scope=${primaryScope}&secondary_scope=${secondaryScope}&task_number=${taskNumber}&type=${type}&info=${info}`;
};

export const fetchTaskInfo: any = (filterId: string, taskNumber: TaskNumberParam) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO,
  };

  const apiRequestObj:APIRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${filterId}/data?primary_scope=task_report_analysis&secondary_scope=meta_data&task_number=${taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchPrecompIntervalByType: any = (params: FetchTaskReportByActualIntervalParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL,
  };
  const {
    taskNumber, intervalType, intervalInfo, filterId,
  } = params || {};
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${filterId}/data?${generateQueryParams('task_report_analysis', 'interval_graph', taskNumber, intervalType, intervalInfo)}`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchPrecomputedRawTaskReports: any = (params: FetchRawTaskReportsParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_PRECOMPUTE_RAW_TASK_REPORTS,
  };
  const filterString = preparePrecompFilterList(params);
  const column = 'actual_interval_months,actual_interval_flight_cycles,actual_interval_flight_hours,finding_type_description,id_aircraft,ac_series,corrective_action,finding_description';
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?task_number=${params?.taskNumber}&primary_scope=task_report_analysis&secondary_scope=task_report_raw_task_table_data&offset=${params?.offset}&limit=${params?.limit}&columns=${column}`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  if (filterString) {
    apiRequestObj.path = `${apiRequestObj.path}&filters=${filterString}`;
  }
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchPrecomputedRawTaskReportsColumnSearch: any = (params: FetchRawTaskReportsColumnSearchParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH,
  };
  const filterString = searchColumnString(params);
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?task_number=${params?.taskNumber}&primary_scope=task_report_analysis&secondary_scope=task_report_raw_task_table_data&columns=${params?.searchColumn?.columnName}&filters=${filterString}&distinct=1`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoFleetLevelGeoRegionChart: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&secondary_scope=fleet_region_vs_finding_rate&task_number=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoFleetLevelFindingChart: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&secondary_scope=fleet_avg_findings_per_msn&task_number=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoOperatorLevelReportReceivedYearly: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&task_number=${params?.taskNumber}&secondary_scope=operator_task_report_per_year`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoOperatorLevelFindingChart: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&task_number=${params?.taskNumber}&secondary_scope=operator_avg_findings_per_msn`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoOperatorLevelFindingRateByMsn: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&task_number=${params?.taskNumber}&secondary_scope=number_of_task_reports_and_associated_finding_rate_by_msn`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskReportOperatorCard: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_OPERATOR_CARD,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/${TASK_REPORT_OPERATORCARD_API_PATH}=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskReportWorldWideFleetCard: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/${TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH}=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
