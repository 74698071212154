/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Container, DataTable, Pagination, Row,
} from '@airbus/components-react';
import { useNavigate } from 'react-router-dom';

import TableSearch from '../../Shared/TableSearch/TableSearch';
import { getTableColumns, sortTableData } from './tableStructure';
import {
  filtersValues, maintenanceTask, taskListFilters, taskListSort,
} from '../../../models/programAnalysis/programAnalysisTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  updateSelectedPage, updateSelectedTask, updateTaskListSort, updateTasksObject,
} from '../../../models/programAnalysis/programAnalysisSlice';

import { simpleCompareArr } from './utils';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import { appContext } from '../../../utils/context/userContext';

import {
  MPD_TASK_CODE,
  MPD_TASK_DESCRIPTION,
  SOURCE_DOCUMENT_TYPE,
  MPD_TASK_REFERENCE,
  NUMBER_OF_TASK_REPORT,
  BUILD_ID,
  OPPORTUNITY,
  SUFFICIENT_TASK_REPORT,
  COST_AIRCRAFT_YEAR,
  OPERATOR_FINDING_RATE,
  OPERATOR_YIELD,
} from '../../../models/programAnalysis/constants';
import './TaskList.scss';

const TaskList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const taskObj = useAppSelector((state) => state.programAnalysis.tasks);
  const buildInfo = useAppSelector((state) => state.programAnalysis.build);
  const [currentPage, setCurrentPage] = useState(taskObj.currentPage);
  const [currentMPD, setCurrentMPD] = useState(taskObj.selectedTaskReference || '');
  const [taskData, setTaskData] = useState<maintenanceTask[]>(taskObj?.data || []);
  const [filteredTaskData, setFilteredTaskData] = useState<maintenanceTask[]>([]);
  const [renderCount, setRenderCount] = useState<number>(0);
  const topFilterKeys = [OPPORTUNITY, NUMBER_OF_TASK_REPORT, SUFFICIENT_TASK_REPORT, COST_AIRCRAFT_YEAR, OPERATOR_FINDING_RATE, OPERATOR_YIELD];
  const { userType } = useContext(appContext);

  /*
    Helper function to determine which text to display depending on how much data we have
   */
  const paginationTextToDisplay = (data: maintenanceTask[]) => {
    if (data.length <= 0) {
      return 'Showing 0 - 0 of 0 results';
    }
    return `Showing ${1 + (currentPage - 1) * 10} - ${currentPage * 10 >= data.length ? data.length : currentPage * 10} of ${data.length} results`;
  };

  const dispatchFilters = (filters: filtersValues) => {
    dispatch(updateTasksObject({ filters }));
    // Reset the table page to "1" after applying filter
    setCurrentPage(1);
  };

  useEffect(() => {
    const sortedAllData = sortTableData(taskObj.data || [], taskObj.sort || []);
    setTaskData(sortedAllData);
  }, [taskObj.data, taskObj.sort]);

  useEffect(() => {
    const sortedFilteredData = sortTableData(taskObj.filteredData || [], taskObj.sort || []);
    setFilteredTaskData(sortedFilteredData);
  }, [taskObj.sort, taskObj.filteredData]);

  /*
    Callback for the DataTable to call when something has changed internally (sorting, for example)
    We only dispatch what's changed (primarily the sort) and the useEffect(s) above do the real work
   */
  const onQUpdate = useCallback(
    (queryParams: { pageIndex: number; pageSize: number; sortBy: taskListSort[] }) => {
      if (!simpleCompareArr(queryParams.sortBy, taskObj.sort)) {
        dispatch(updateTaskListSort({ sort: queryParams.sortBy }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskObj.sort],
  );

  /**
   * returns -> filteredTaskData if applying any filter returns data
   * returns -> [] if applying any filter returns no data
   * returns -> taskData default data before applying filters
   */
  const displayData = () => {
    if (filteredTaskData && filteredTaskData.length) {
      return filteredTaskData;
    }
    if (Object.keys(taskObj.filters || {}).length) {
      return [];
    }
    return taskData;
  };

  /**
   * Reset the page to 1 whenever a new filter is applied
   * Preserve the current page when navigates to task report analysis page and returned back
  */
  useEffect(() => {
    if (renderCount > 1) {
      setCurrentPage(1);
    }
    setRenderCount((count) => count + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData().length]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSelectedMPD = useCallback((data: any) => {
    const selectedMPD = data[MPD_TASK_REFERENCE];
    setCurrentMPD(selectedMPD);
    dispatch(updateSelectedTask({ selectedTaskReference: selectedMPD, currentPage }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Container className="maintenance-table-container">
      <Row>
        <div>
          <p className="title"> Maintenance Task List</p>
        </div>
        <TableSearch
          filters={taskObj.filters as taskListFilters}
          setFilters={dispatchFilters}
          data={displayData()}
          columnsOptions={[
            { label: 'Task Number', value: MPD_TASK_REFERENCE },
            { label: 'Task Description', value: MPD_TASK_DESCRIPTION },
            { label: 'Task Code', value: MPD_TASK_CODE },
            { label: 'Source', value: SOURCE_DOCUMENT_TYPE },
          ]}
          resetFilters={() => {
            /** preserve the top level filters and clear only the table search filters */
            const existingFilters = taskObj.filters;
            const finalFilters = Object.keys(existingFilters || {}).reduce((obj: any, key) => {
              if (topFilterKeys.includes(key)) {
                // eslint-disable-next-line no-param-reassign
                obj[key] = existingFilters?.[key];
              }
              return obj;
            }, {});
            dispatchFilters(finalFilters);
          }}
        />
        <DataTable
          className="table-mtt"
          columns={getTableColumns(currentMPD, userType)}
          defaultRowsPerPage={10}
          data={displayData().slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)}
          onRowClick={updateSelectedMPD}
          aria-label="maintenance-tasks-table"
          onQueryUpdate={onQUpdate}
          sortBy={taskObj.sort}
        />
        <div className="table-pagination">
          <p>{paginationTextToDisplay(displayData())}</p>
          <Pagination
            page={currentPage}
            count={displayData().length || 1}
            rowsPerPage={10}
            onPageChange={(event, page) => setCurrentPage(page)}
            showGoto
          />
        </div>
        <Button
          variant="primary"
          className="button"
          disabled={currentMPD === ''}
          onClick={() => {
            dispatch(updateSelectedPage({ currentPage }));
            const operator = secureLS.get('operator');
            navigate(`../task-report/${buildInfo[BUILD_ID]}/task-number/${currentMPD}?operator=${operator}`);
          }}
        >
          Continue
        </Button>
      </Row>
    </Container>
  );
};

export default TaskList;
