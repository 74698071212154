import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  CardTitle,
  Title,
  Toggle,
} from '@airbus/components-react';
import { useParams } from 'react-router-dom';
import SkywiseCrown from '../Shared/SkywiseCrown/SkywiseCrown';
import TooltipComponent from '../Shared/MpdTooltip/mpdTooltip';
import { LOCALES } from '../../assets/locale';
import QuickLinks from '../Shared/QuickLinks/QuickLinks';
import { unscheduleDashBoardQLinks } from '../../__mocks__/TaskReportAnalysis/QuickLinks';
import GraphCard from '../UnscheduledEvents/GraphCard';
import GenericTable from '../Shared/GenericTable/GenericTable';
import { generateHidableColumns, getTableColumns, TableColumnState } from '../MpdTaskTable/mpdTableStructureUtils';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import {
  fetchOiHeaderCardData, fetchTableData, fetchUnscheduleFindingRateData, fetchTableFilterData, fetchFleetwideGraphData,
} from '../../models/unscheduledEventsPrecompModel/unscheduledEventsPrecompAsyncThunk';
import { createHeaderCardArray } from '../UnscheduledEvents/utils';
import CardComponent from '../Shared/Card/CardComponent';
import { generateTableColumns, precomputeGraphOIVsFindingRate, createFleetwideGraph } from './unscheduledEventsPrecompUtils';
import { DEFAULT_TABLE_DATA } from '../TaskReportAnalysis/constants';
import { updateTaskNumber } from '../../models/unscheduledEventsPrecompModel/unscheduledEventsPrecompSlice';
import NoData from '../Shared/Charts/NoData';

export default function UnscheduledEventsPrecomp() {
  const dispatch = useAppDispatch();
  const { filterId, taskNumber } = useParams();
  const [headerCardData, setHeaderCardData] = useState([]);
  const [finding, setFinding] = useState({}); // This state is declared to handle 'OI Reported vs Finding Rate per A/C age' graph data

  /**
   * This is to prevent extra API call when table loads for first time,
   * since <GenericTable/> has useEffect for filters, which runs first time
   * as well and calls `onPaginationChangeCallback`.
   * */
  const [paginationFirstRender, setPaginationFirstRender] = useState(true);
  const [searchPair, setSearchPair] = useState({ columnName: '', columnInput: '' });
  const [searchOptions, setSearchOptions] = useState<Array<{ [key: string]: string }>>([]);
  const [filterGroup, setFilterGroup] = useState<Array<{ columnName: string, columnInput: string }>>([]);

  const {
    table,
    tableFilter,
    taskNumber: oldTaskNumber,
    fleetwideGraph,
    oiHeaderCardData,
    enhancedAnalysisState,
    oiHeaderCardDataLoading,
    oiHeaderCardDataError,
    findingRateGraph,
  } = useAppSelector((state: RootState) => state.unscheduledEventsPrecomp);

  const ata2dNumber = taskNumber?.slice(0, 2);

  const onPaginationChange = (params: { pageSize: number, pageIndex: number }) => {
    if (!paginationFirstRender) {
      dispatch(fetchTableData(filterId, ata2dNumber, params.pageIndex + 1, params.pageSize, filterGroup)());
    } else {
      setPaginationFirstRender(false);
    }
  };

  useEffect(() => {
    // Calls APIs if error occurred
    const error = oiHeaderCardDataError || table.error || findingRateGraph.error || fleetwideGraph.error;

    /* istanbul ignore else */
    if (taskNumber !== oldTaskNumber || error) {
      dispatch(updateTaskNumber(taskNumber));
      dispatch(fetchOiHeaderCardData(filterId, 'unscheduled_events', 'headers', ata2dNumber)());
      dispatch(fetchTableData(filterId, ata2dNumber, 1, DEFAULT_TABLE_DATA.limit)());
      dispatch(fetchUnscheduleFindingRateData(filterId, ata2dNumber, taskNumber)());
      dispatch(fetchFleetwideGraphData(filterId, ata2dNumber)());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeaderCardData(createHeaderCardArray(oiHeaderCardData, enhancedAnalysisState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oiHeaderCardData]);

  useEffect(() => {
    // Below method will formate finding rate data as per graph requirement
    const formattedGraphOIVsFindingRatedata = precomputeGraphOIVsFindingRate(findingRateGraph.findingRate);
    setFinding(formattedGraphOIVsFindingRatedata);
  }, [findingRateGraph.findingRate]);

  useEffect(() => {
    const filterOptions: Array<{ [key: string]: string }> = [];
    tableFilter.column_value.forEach((option) => (
      filterOptions.push({ [tableFilter.column_name]: option })
    ));
    setSearchOptions(filterOptions);
  }, [tableFilter]);

  const onUpdateColumnFilterValue: (data: string) => void = (data) => {
    setSearchPair((prevState) => ({ ...prevState, columnInput: data }));
  };

  const onUpdateColumnFilter: (data: string) => void = (data) => {
    /* istanbul ignore else */
    if (data !== searchPair.columnName) {
      setSearchPair((prevState) => ({ ...prevState, columnName: data }));
    }
  };

  const onDeleteFromFilterGroup: (data: searchPair[]) => void = (data) => {
    const deleteColumnNameList = data.map((column) => column.columnName);
    setFilterGroup((prevState) => (
      prevState.filter((filter) => !deleteColumnNameList.includes(filter.columnName))
    ));
  };

  const onUpdateFilterGroup: () => void = () => {
    setFilterGroup((prevState) => {
      const filterGroupList = prevState.filter((filter) => filter.columnName !== searchPair.columnName);
      return [...filterGroupList, { columnName: searchPair.columnName, columnInput: searchPair.columnInput }];
    });
  };

  const onFetchColumnFilterValues: (data: string) => void = (data) => {
    setSearchPair((prevState) => ({ ...prevState, columnInput: data }));
    dispatch(fetchTableFilterData(filterId, ata2dNumber, searchPair.columnName, data, filterGroup)());
  };

  const oiVsRsiGraphComponent = (
    !enhancedAnalysisState ? (
      <Card className="graph-card">
        <CardTitle className="card-title">
          {LOCALES.rsi_graph_title}
        </CardTitle>
        <CardContent className="card-content">
          <div className="enhanced-analysis-off-text">
            Data available for enhanced analysis
          </div>
        </CardContent>
      </Card>
    ) : (
      <GraphCard graph={{}} loading={false} title="OI Reported vs RSI per A/C age" enhancedAnalysis />
    )
  );

  const tableComponent = () => {
    let response = (
      <GenericTable
        cssClass="unsched-events-table"
        tableTitle={LOCALES.unschedule_event_table}
        tableCols={table.headers}
        tableData={table.data}
        totalDataCount={table.count}
        onPaginationChangeCallback={onPaginationChange}
        Loading={table.loading}
        generateTableColumns={generateTableColumns}
        tableColumnState={TableColumnState}
        getTableColumns={getTableColumns}
        generateHidableColumns={generateHidableColumns}
        noSearchKeySizeLimitColumns={table.filter_data} /** List of columns names, for which a keystroke limit to trigger onChange in search component is set to 0. */
        searchPair={searchPair} /** A filter pair of columnName and columnValue input by the user */
        columnFilter={searchOptions} /** A columnName to filtered columnValues (based on user input) mapping for user to make selection */
        filterGroup={filterGroup} /** A list of filters applied */
        updateColumnFilter={onUpdateColumnFilter} /** callback when user selects the filter column from dropdown */
        updateColumnFilterValue={onUpdateColumnFilterValue} /** callback when user types in search input */
        updateFilterGroup={onUpdateFilterGroup} /** callback when user clicks on search */
        deleteFromFilterGroup={onDeleteFromFilterGroup} /** callback when user removes a filter */
        fetchColumnFilterValues={onFetchColumnFilterValues} /** callback to fetch filtered values from API when user types in searchbox */
        filterChipPositionDetached /** If true, the filter chips will be displayed above the table instead being attached to the table search component. */
        isDataDynamic
      />
    );

    if (table.error) {
      response = (
        <>
          <Title className="no-ue-table-data-title">{LOCALES.unschedule_event_table}</Title>
          <NoData type="error" message="Error occured. Please try after sometime." customClassName="api-fetching-error" />
        </>
      );
    } else if (!table.loading && !table.count) {
      response = (
        <>
          <Title className="no-ue-table-data-title">{LOCALES.unschedule_event_table}</Title>
          <NoData message="No data" customClassName="data-empty" />
        </>
      );
    }
    return response;
  };

  const fleetwide = useMemo(() => {
    return createFleetwideGraph(fleetwideGraph.fleetwide, fleetwideGraph.operator, fleetwideGraph.yearRange);
  }, [fleetwideGraph]);

  return (
    <div>
      <div className="enhanced-cal">
        <SkywiseCrown />
        Enhanced Analysis
        <div style={{ marginTop: '3px' }}>
          <TooltipComponent title="" data={LOCALES.enhance_Info} placement="left" />
        </div>
        <Toggle className="enhanced-toggle" aria-label="enhance-analysis-toggle" checked={enhancedAnalysisState} disabled />
      </div>
      <CardComponent
        cardHeader={[]}
        cardBody={headerCardData}
        noOfElementsPerRow={4}
        config={{
          className: 'card-full-screen',
        }}
        loading={oiHeaderCardDataLoading}
        status={oiHeaderCardDataError ? 'failed' : ''}
      />
      <QuickLinks quickLinks={unscheduleDashBoardQLinks.quickLinks} title={unscheduleDashBoardQLinks.title} />
      <div className="row-graphs">
        <div className="graph-size">
          <GraphCard graph={fleetwide} title={LOCALES.fleetwide_graph_title} loading={fleetwideGraph.loading} error={fleetwideGraph.error} enhancedAnalysis={false} />
        </div>
      </div>
      <div className="row-graphs">
        <div className="graph-size">
          <GraphCard graph={finding} title={LOCALES.findingrate_graph_title} loading={findingRateGraph.loading} error={findingRateGraph.error} enhancedAnalysis={false} />
        </div>
        <div className="graph-size">
          {oiVsRsiGraphComponent}
        </div>
      </div>
      {tableComponent()}
    </div>
  );
}
