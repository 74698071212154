import React from 'react';
import {
  Card, CardContent, CardTitle,
} from '@airbus/components-react';
import { Bar } from 'react-chartjs-2';
import './UnscheduledEvents';
import '../../container/TaskReport/TaskReport.scss';
import { ChartData, ChartOptions } from 'chart.js';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import SkywiseCrown from '../Shared/SkywiseCrown/SkywiseCrown';
import NoData from '../Shared/Charts/NoData';

export type BarGraphCardProps = {
  loading: boolean;
  error?: boolean;
  graph: {
    options?: ChartOptions<'bar'>;
    data?: ChartData<'bar'>;
    height?: number;
  } | undefined;
  title: string;
  /**
   * Use if graph supports enhanced analysis.
   *
   * If `true`, a 'crown' icon will be shown after the graph title.
   */
  enhancedAnalysis?: boolean;
};

const BarGraphCard = (props: BarGraphCardProps) => {
  const {
    graph,
    loading,
    error,
    title,
    enhancedAnalysis,
  } = props;
  const compToRender = () => {
    let content;
    if (loading || !graph) {
      content = (
        <div className="loading-spinner">
          <SpinnerComponent size="small" label="" />
        </div>
      );
    } else if (error) {
      content = (
        <NoData type="error" message="Error occured. Please try after sometime. " customClassName="api-fetching-error" />
      );
    } else if (graph.data && graph.data.labels && graph.data.labels.length !== 0) {
      content = (
        <Bar data={graph.data} options={graph.options} height={graph?.height} />
      );
    } else {
      content = (
        <NoData message="No data" customClassName="data-empty" />
      );
    }
    return (
      <>
        <CardTitle className="card-title">
          {title}
          {enhancedAnalysis && <SkywiseCrown />}
        </CardTitle>
        <CardContent className="card-content">
          {content}
        </CardContent>
      </>
    );
  };

  return <Card className="graph-card">{compToRender()}</Card>;
};

BarGraphCard.defaultProps = {
  enhancedAnalysis: null,
  error: false,
};

export default BarGraphCard;
