import { Methods } from '../../utils/customHooks/apiTypes';

// API
export const TASK_REPORT_API_PATH = 'api/bcm/program-analysis/task-report-analysis';
export const TASK_REPORT_API_POST_METHOD = 'post' as Methods;
export const TASK_REPORT_PRECOMPUTE_API_PATH = 'api/precompute/scopes';
export const TASK_REPORT_API_GET_METHOD = 'get' as Methods;
// operatorcard path
export const TASK_REPORT_OPERATORCARD_API_PATH = 'data/operator-key-params?task_number';
export const TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH = 'data/fleet-key-params?task_number';
// Slice
export const TASKS_SLICE = 'task';

// Async thunk actions
export const FETCH_TASK_INFO = 'fetchTaskInfo';
export const FETCH_TASK_INFO_TABLE_NAME = 'smac_mpd_task_metadata';
export const FETCH_TASK_INFO_COLUMNS = [
  'mpd_task_reference',
  'source_document_reference',
  'source_document_type',
  'mpd_task_description',
  'mpd_task_code',
  'mpd_100_threshold',
  'mpd_100_interval',
  'applicability_text',
  'is_calculable',
];

// precomp
export const FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION = 'fetchTaskInfoFleetLevelGeoRegion';
export const FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART = 'fetchTaskInfoFleetLevelFindingChart';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY = 'fetchTaskInfoOperatorLevelReportReceivedYearly';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART = 'fetchTaskInfoOperatorLevelFindingChart';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN = 'fetchTaskInfoOperatorLevelFindingRateByMsn';
export const FETCH_TASK_REPORT_OPERATOR_CARD = 'fetchTaskReportOperatorCard';
export const FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD = 'fetchTaskReportWorldWideFleetCard';

export const FETCH_RAW_TASK_REPORTS = 'fetchRawTaskReports';
export const FETCH_RAW_TASK_REPORTS_COLUMN_SEARCH = 'fetchRawTaskReportsColumnSearch';
export const FETCH_PRECOMPUTE_RAW_TASK_REPORTS = 'fetchPrecomputeRawTaskReports';
export const FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH = 'fetchPrecomputeRawTaskReportsColumnSearch';
export const FETCH_RAW_TASK_REPORTS_TABLE_NAME = 'smac_post_task_assessment_raw_task_report';
export const FETCH_RAW_TASK_REPORTS_COLUMNS = [
  'actual_interval_months',
  'actual_interval_flight_cycles',
  'actual_interval_flight_hours',
  'finding_type_description',
  'id_aircraft',
  'ac_series',
  'corrective_action',
];

export const FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL = 'fetchPrecompTaskReportByActualInterval';
