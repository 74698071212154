import { initialState } from './taskReportSlice';
import { generateTaskReportMonthYearFormat } from './taskReportUtils';

export const fetchTaskInfoPending = (state: TaskReportState) => ({
  ...state,
  loading: true,
  status: 'idle',
});

export const fetchTaskInfoFulfilled = (state: TaskReportState, action: FetchTaskInfoFulfilledAction) => ({
  ...state,
  loading: false,
  taskInfo: action.payload.data[0],
  status: 'succeeded',
  httpStatus: 200,
});

export const fetchTaskInfoRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  loading: false,
  error: action.payload?.error,
  httpStatus: action.payload?.httpStatus,
  status: 'failed',
});

export const fetchTaskInfoFleetLevelGeoRegionChartPending = (state: TaskReportState) => ({
  ...state,
  findingRateByGeoRegion: {
    ...state.findingRateByGeoRegion,
    loading: true,
    status: 'idle',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoFleetLevelGeoRegionChartFulfilled = (state: TaskReportState, { payload: { data } } : FetchFindingRateByGeoRegionFulfilledAction) => ({
  ...state,
  findingRateByGeoRegion: {
    ...state.findingRateByGeoRegion,
    all_climate_regions: data?.all_climate_regions || [],
    climate_fleet_finding_rate: data?.climate_fleet_finding_rate || [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoFleetLevelGeoRegionChartRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  findingRateByGeoRegion: {
    ...state.findingRateByGeoRegion,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const fetchTaskInfoFleetLevelFindingChartPending = (state: TaskReportState) => ({
  ...state,
  fleetWideFindingsByFindingType: {
    ...state.fleetWideFindingsByFindingType,
    loading: true,
    status: 'idle',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoFleetLevelFindingChartFulfilled = (state: TaskReportState, { payload: { data } } : FetchFindingsByFindingTypeFulfilledAction) => ({
  ...state,
  fleetWideFindingsByFindingType: {
    ...state.fleetWideFindingsByFindingType,
    fleet_finding_list: data?.fleet_finding_list || [],
    fleet_finding_avg_count: data?.fleet_finding_avg_count || [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoFleetLevelFindingChartRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  fleetWideFindingsByFindingType: {
    ...state.fleetWideFindingsByFindingType,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});
// operator level finding vs fing chart
export const fetchTaskInfoOperatorLevelFindingChartPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevelFindingChart: {
    ...state.taskInfoOperatorLevelFindingChart,
    loading: true,
    status: 'idle',
  },
});
// eslint-disable-next-line @typescript-eslint/no-shadow
// here finding
export const fetchTaskInfoOperatorLevelFindingChartFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoperatorLevelFindingChartFulfilledAction) => ({
  ...state,
  taskInfoOperatorLevelFindingChart: {
    ...state.taskInfoOperatorLevelFindingChart,
    operator_finding_list: data?.operator_finding_list || [],
    operator_finding_avg_count: data?.operator_finding_avg_count || [],
    loading: false,
    error: '',
    status: 'succeeded',
    httpStatus: 200,
  },
});

export const fetchTaskInfoOperatorLevelFindingChartRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevelFindingChart: {
    ...state.taskInfoOperatorLevelFindingChart,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});
// finding rate by msn
export const fetchTaskInfoOperatorLevelFindingRateByMsnPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevelFindingRateByMsn: {
    ...state.taskInfoOperatorLevelFindingRateByMsn,
    loading: true,
    status: 'idle',
  },
});
// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoOperatorLevelFindingRateByMsnFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoperatorLevelFindingRateByMsnFulfilledAction) => ({
  ...state,
  taskInfoOperatorLevelFindingRateByMsn: {
    ...state.taskInfoOperatorLevelFindingRateByMsn,
    id_aircraft: data?.id_aircraft || [],
    all_finding_per_id_aircraft_count: data?.all_finding_per_id_aircraft_count || [],
    nil_finding_per_id_aircraft_count: data?.nil_finding_per_id_aircraft_count || [],
    finding_rate_per_id_aircraft_count: data?.finding_rate_per_id_aircraft_count || [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoOperatorLevelFindingRateByMsnRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevelFindingRateByMsn: {
    ...state.taskInfoOperatorLevelFindingRateByMsn,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});
// operator level finding yearly chart
export const fetchTaskInfoOperatorLevelReportReceivedYearlyPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevelReportReceivedYearly: {
    ...state.taskInfoOperatorLevelReportReceivedYearly,
    loading: true,
    status: 'idle',
  },
});
// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoOperatorLevelReportReceivedYearlyFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoperatorLevelReportReceivedYearlyFulfilledAction) => ({
  ...state,
  taskInfoOperatorLevelReportReceivedYearly: {
    ...state.taskInfoOperatorLevelReportReceivedYearly,
    operator_task_year: generateTaskReportMonthYearFormat(data?.operator_task_year || [], data?.operator_task_year_count || [], data?.operator_task_month || []),
    operator_task_year_count: data?.operator_task_year_count || [],
    /* operator_task_month is set to empty array [] by default as the data is appended with operator_task_year */
    operator_task_month: data?.operator_task_month || [],
    loading: false,
    error: '',
    status: 'succeeded',
    httpStatus: 200,
  },
});

export const fetchTaskInfoOperatorLevelReportReceivedYearlyRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevelReportReceivedYearly: {
    ...state.taskInfoOperatorLevelReportReceivedYearly,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const fetchTaskReportOperatorCardPending = (state: TaskReportState) => ({
  ...state,
  taskReportAnalysisOperatorCard: {
    ...state.taskReportAnalysisOperatorCard,
    loading: true,
    status: 'idle',
  },
});

export const fetchTaskReportOperatorCardFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskReportOperatorCardFulfilledAction) => ({
  ...state,
  taskReportAnalysisOperatorCard: {
    ...state.taskReportAnalysisOperatorCard,
    operator_finding_rate: data.length ? data[0].operator_finding_rate : '',
    total_tr: data.length ? data[0].total_tr : '',
    total_ac_reported: data.length ? data[0].total_ac_reported : '',
    sufficient_reported_data_for_optimization: data.length ? data[0].sufficient_reported_data_for_optimization : '',
    sufficent_reported_bar: data.length ? data[0].sufficent_reported_bar : '',
    sufficient_task_report: data.length ? data[0].sufficient_task_report : '',
    avg_yield: data.length ? data[0].avg_yield : '',
    ffr: data.length ? data[0].ffr : '',
    me: data.length ? data[0].me : '',
    loading: false,
    status: 'succeeded',
    httpStatus: 200,
  },
});
export const fetchTaskReportOperatorCardRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskReportAnalysisOperatorCard: {
    ...state.taskReportAnalysisOperatorCard,
    loading: false,
    status: 'failed',
    httpStatus: action.payload?.httpStatus,
    error: action.payload?.error,
  },
});

export const fetchTaskReportWorldWideCardFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskReportWorldWideCardFulfilledAction) => ({
  ...state,
  taskInfoFleetCard: {
    ...state.taskInfoFleetCard,
    world_wide_finding_rate: data.length ? data[0].world_wide_finding_rate : '',
    loading: false,
    status: 'succeeded',
    httpStatus: 200,
  },
});
export const fetchTaskReportWorldWideCardRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetCard: {
    ...state.taskInfoFleetCard,
    loading: false,
    status: 'failed',
    httpStatus: action.payload?.httpStatus,
    error: action.payload?.error,
  },
});

export const fetchTaskReportWorldWideFleetCardPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetCard: {
    ...state.taskInfoFleetCard,
    loading: true,
    status: 'idle',
  },
});

export const precompActualIntervalByTypeInitReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    loading: true,
    status: 'idle',
  },
});

export const precompActualIntervalByTypeFulfilledReducer = (state: TaskReportState, { payload }: FetchTaskReportsByActualIntervalFulfilledAction) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    data: payload.data,
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const precompActualIntervalByTypeRejectedReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    loading: false,
    httpStatus: 500,
    status: 'failed',
  },
});

export const precompCumulativeIntervalByTypeInitReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    loading: true,
    status: 'idle',
  },
});

export const precompCumulativeIntervalByTypeFulfilledReducer = (state: TaskReportState, { payload }: FetchTaskReportsByActualIntervalFulfilledAction) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    data: payload.data,
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const precompCumulativeIntervalByTypeRejectedReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    loading: false,
    error: '',
    httpStatus: 500,
    status: 'failed',
  },
});

/**
 * Update columnName after user selects an option
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updatePrecomputeRawTaskReportfilterReducer = (state: TaskReportState, action: rawTaskReportColAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    searchPairData: {
      ...state.precomputerawTaskReports.searchPairData,
      columnName: action.payload,
    },
  },
});

export const updatePrecomputeRawTaskReportfilterValueReducer = (state: TaskReportState, action: rawTaskReportColAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    searchPairData: {
      ...state.precomputerawTaskReports.searchPairData,
      columnInput: action.payload,
    },
  },
});

export const updatePrecomputeRawTaskReportfilterGroupReducer = (state: TaskReportState, action: rawTaskReportfilterGroup) => {
  let updatedFilterGroup = state.precomputerawTaskReports.filterGroup.filter((col) => col.columnName !== action.payload[0].columnName);
  updatedFilterGroup = updatedFilterGroup.concat(action.payload);
  return {
    ...state,
    precomputerawTaskReports: {
      ...state.precomputerawTaskReports,
      filterGroup: updatedFilterGroup,
    },
  };
};

export const deletePrecomputeRawTaskReportfilterGroupReducer = (state: TaskReportState, action: rawTaskReportfilterGroup) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    filterGroup: state.precomputerawTaskReports.filterGroup.filter((element) => element.columnName !== action.payload[0].columnName),
  },
});
/**
 * clear filterGroup
 * @param state current state
 * @returns updated state
 */
export const clearRawTaskReportfilterGroupReducer = (state: TaskReportState) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    filterGroup: [],
  },
});

/**
 * clear task report
 * @param state current state
 * @returns updated state
 */
export const clearTaskReportfilterGroupReducer = (state: TaskReportState) => ({
  ...state,
  loading: initialState.loading,
  error: initialState.error,
  httpStatus: initialState.httpStatus,
  status: initialState.status,
  taskInfo: initialState.taskInfo,
});

/**
 * Update filterId and taskNumber for performance tuning ( For avoiding repitition of API calls )
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updateTaskReportPerformanceTuningReducer = (state: TaskReportState, { payload }: UpdatePerformanceTuningFulfilledAction) => ({
  ...state,
  performance: {
    filterId: payload.filterId || state.performance.filterId,
    taskNumber: payload.taskNumber || state.performance.taskNumber,
    table: payload.table,
  },
});

export const updatePrecomputeTaskReportPerformanceTuningReducer = (state: TaskReportState, { payload }: UpdatePerformanceTuningFulfilledAction) => ({
  ...state,
  performance: {
    filterId: payload.filterId || state.performance.filterId,
    taskNumber: payload.taskNumber || state.performance.taskNumber,
    table: payload.table,
  },
});

/**
 * reset metadata api response status
 * @param state current state
 * @returns updated state
 */
export const resetMetaDataReducer = (state: TaskReportState) => ({
  ...state,
  error: '',
  httpStatus: initialState.httpStatus,
});

export const clearPrevfilterIdReducer = (state: TaskReportState) => ({
  ...state,
  performance: initialState.performance,
});
/**
 * Set precomputerawTaskReports loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const fetchPrecomputeRawTaskReportsPending = (state: TaskReportState) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    loading: true,
    status: 'idle',
  },
});

/**
 * Set precomputerawTaskReports data, total count fetched from api and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const fetchPrecomputeRawTaskReportsFulfilled = (state: TaskReportState, { payload }: FetchPrecomputeRawTaskReportsFulfilledAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    data: payload?.data?.table?.length ? payload.data.table : [],
    totalCount: payload?.data?.count || 0,
    loading: false,
    error: '',
    httpStatus: 200,
    searchPairData: {
      ...state.precomputerawTaskReports.searchPairData,
    },
    searchOptions: [],
    status: 'succeeded',
  },
});

/**
 * Set precomputerawTaskReports error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const fetchPrecomputeRawTaskReportsRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

/**
 * Set rawTaskReports searchOptions fetched from api
 * @param state current state
 * @param action payload
 * @return updated state ( searchOptions => payload.data )
 */
export const fetchPrecomputeRawTaskReportsColumnSearchFulfilled = (state: TaskReportState, { payload }: FetchPrecomputeRawTaskReportsFulfilledAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    searchOptions: payload?.data?.table?.length ? payload.data.table : [],
  },
});
/**
 * Update isTadaOn state to enable and disable tada related response in all graphs
 * @param state current state
 * @returns updated state
 */
export const updateEnhanceStateReducer = (state: TaskReportState) => ({
  ...state,
  tada: { isTadaOn: !state.tada.isTadaOn },
});
/**
 * Clear the precompute raw task report table store data
 * @param state
 * @returns
 */
export const clearPrecomputeRawTaskReportsReducer = (state: TaskReportState) => ({
  ...state,
  precomputerawTaskReports: {
    ...initialState.precomputerawTaskReports,
  },
});
